.registros-table-container {
  max-height: 300px;
  width: 40vw;
  overflow: auto;
  position: relative; /* Asegúrate de que el contenedor esté posicionado para la posición relativa de la cabecera */

  &.small {
    .registros-table {
      th, td {
        font-size: 13px;
        color: rgb(29, 29, 29);
      }
    }
  }

  .registros-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background-color: #e89729;
      color: white;
      z-index: 2; /* Asegúrate de que la cabecera esté por encima del contenido al hacer scroll */

      th {
        color: white !important;
      }
    }

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ccc;
      font-size: 16px; /* Tamaño de fuente aumentado */
      min-width: 120px;
    }

    tbody tr {
      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }
  }
}
