.SelectQuarter {
  width: 100%;
  max-width: 180px;

  padding: 0.5rem 1rem;
  margin: 10px auto 0px;
  
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #e89729;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: #fff2ea;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }

  &:hover {
    border-color: #fff3eb;
  }

  option {
    font-size: 14px;
    color: #f1f1f1;
  }
}