.layout {
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  min-height: 100vh;
  width: 100%;

  background-color: #FCFCFC;
  background-image: url("../Images/Background.jpg");
  background-position: center;
  background-size: cover;


  .LogInx {
    flex-direction: row !important;
  }
  
  
  .bg-overlay {
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(59 59 59 / 39%);
    
    z-index: 1;
  }

  > *:not(.bg-overlay) {
    z-index: 10;
  }
  
  .Header {
    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;
    
    background-color: #000;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 0 0 25px 0;
    
    img {
      height: 50px;
    }
  }

  .StopTimer {
    position: absolute;
    top: 0; right: 0;
    
    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;
    
    background-color: #4734d4;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 0 0 0 25px;
    border: none;
    outline: none;

    cursor: pointer;

    i {
      font-size: 2.2rem;
      color: white;

      &.pi-pause {
        font-size: 2rem;
      }
    }

    &:hover {
      background-color: #d79777;
    }
    
    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: .1;
    }
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .mobile-actions {
      display: none;
    }
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      min-height: calc(100vh - 80px);

      .step-container, .step-wrap-container {
        min-height: unset;
      }
      
      .step-btn {
        display: none;
      }

      .mobile-actions {
        display: flex;
        
        .step-btn {
          display: grid;
        }
      }
    }
  }
}